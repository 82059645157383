import {
  COMPETITIVE_INTELLIGENCE_URL,
  RANK_TRACKER_V2_BASE
} from 'constants/routes'
import { parseQueryString } from 'helpers/url'

export const rankTrackerGroupPageBreadcrumbs = (t, history) => {
  const { group, category = 0 } =
    parseQueryString(history.location?.search) || {}

  if (!group) {
    return null
  }

  return [
    {
      label: t('rank_tracker:RankTrackerPage.title', 'Rank Tracker'),
      url: `${RANK_TRACKER_V2_BASE}?id=${category}`
    },
    {
      label: group
    }
  ]
}

export const competitiveIntelligencePageBreadcrumbs = (t, name) => {
  if (!name) {
    return null
  }

  return [
    {
      label: t(
        'sidebar:competitiveIntelligence.header',
        'Competitive Intelligence'
      ),
      url: COMPETITIVE_INTELLIGENCE_URL
    },
    {
      label: name
    }
  ]
}

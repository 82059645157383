import isEqual from 'lodash/isEqual'

//  returns the value of a nested property within an object,
//  using dot notation path
// (e.g.)
// let obj = { x: { y: 2 } }
// getObjProperty(obj, 'x.y')  => 2
export const getObjProperty = (obj, path) => {
  if (path) {
    return path.split('.').reduce((acc, part) => acc && acc[part], obj)
  }
}

// Returns an object containing only keys passed as argument
export const pick = (object, keys) => {
  return keys.reduce((obj, key) => {
    // eslint-disable-next-line no-prototype-builtins
    if (object && object.hasOwnProperty(key)) {
      obj[key] = object[key]
    }
    return obj
  }, {})
}

export const isObject = val =>
  !(val instanceof Date) && !Array.isArray(val) && val instanceof Object

export const sumKeys = (arr, keys) => {
  return (
    Array.isArray(arr) &&
    arr.reduce((accumulator, currentObject) => {
      keys
        .filter(key => currentObject[key] !== undefined)
        .forEach(key => {
          if (accumulator[key] === undefined || accumulator[key] === null) {
            accumulator[key] = currentObject[key]
          } else if (isObject(accumulator[key])) {
            accumulator[key] = isEqual(accumulator[key], currentObject[key])
              ? accumulator[key]
              : currentObject[key]
          } else {
            accumulator[key] += currentObject[key]
          }
        })

      return accumulator
    }, {})
  )
}

export const sumObjectKeys = (arr, keys) =>
  arr.reduce((accumulator, currentObject) => {
    keys
      .filter(key => !!currentObject[key])
      .forEach(key => {
        if (accumulator[key] === undefined) {
          accumulator[key] = 1
        } else {
          accumulator[key] += 1
        }
      })
    return accumulator
  }, {})

export const maxKeys = (arr, keys) => {
  return arr.reduce((accumulator, currentObject) => {
    keys.forEach(key => {
      const currentValue = currentObject[key]
      if (accumulator[key] === undefined) {
        accumulator[key] = currentValue
      } else {
        accumulator[key] =
          currentValue > accumulator[key] ? currentValue : accumulator[key]
      }
    })
    return accumulator
  }, {})
}

const arrayLengthWithoutNulls = (arr, key) => {
  return arr.reduce((count, item) => count + (item[key] !== null ? 1 : 0), 0)
}

export const avgKeys = (arr, keys, skipNullValues = false) => {
  const group = sumKeys(arr, keys)

  return Object.fromEntries(
    Object.entries(group).map(([k, v]) => {
      // Count the non-null values for each key
      const divider = skipNullValues
        ? arrayLengthWithoutNulls(arr, k)
        : arr.length

      const avg = divider > 0 ? Math.round(v / divider) : null
      return [k, avg]
    })
  )
}

export const avgRating = (arr, keys, skipNullValues = false) => {
  const group = sumKeys(arr, keys)

  return Object.fromEntries(
    Object.entries(group).map(([k, v]) => {
      const divider = skipNullValues
        ? arrayLengthWithoutNulls(arr, k)
        : arr.length

      const avg = divider > 0 ? (v / divider).toFixed(1) : null
      return [k, avg]
    })
  )
}

export const avgKeysCurrency = (arr, keys, skipNullValues = false) => {
  const group = sumKeys(arr, keys)

  return Object.fromEntries(
    Object.entries(group).map(([k, v]) => {
      const divider = skipNullValues
        ? arrayLengthWithoutNulls(arr, k)
        : arr.length

      const avg = divider > 0 ? (v / divider).toFixed(2) : null
      return [k, avg]
    })
  )
}

export const removeNullAndEmpty = obj =>
  Object.entries(obj).reduce(
    (all, [key, value]) =>
      value === null || value?.length === 0 ? all : { ...all, [key]: value },
    {}
  )
